export const dataList = [
  {
    title: '前端',
    backgroundColor: '#1E70FF',
    children: [
      {
        title: '官网多语言版本\n(可独立收录)',
        svg: 'language',
        text: ['支持英文、简体中文、等多种语', '言']
      },
      {
        title: '自适应访问终端',
        svg: 'fullScreen',
        text: ['移动端与PC端自动适应变更，', '迎合客户设备']
      },
      {
        title: '货币切换',
        svg: 'currency',
        text: ['支持切换网站计算货币，适应全', '球用户']
      },
      {
        title: 'SSL证书',
        svg: 'ssl',
        text: ['保证网站安全性，谷歌收录更友', '好']
      },
      {
        title: '全球CDN',
        svg: 'cdn',
        text: ['全球CDN加速，世界各地秒开网站']
      },
      {
        title: '风格模板任意切换',
        svg: 'template',
        text: ['50+风格免费任意切换']
      }
    ]
  },
  {
    title: '产品',
    backgroundColor: '#11E180',
    children: [
      {
        title: '零售与小额批发模式',
        svg: 'retail',
        text: ['支持产品变体与产品批发价格', '设置']
      },
      {
        title: '产品导入',
        svg: 'import',
        text: ['支持速卖通、', 'Shopify、Xshoppy产品导入']
      },
      {
        title: '平台物流',
        svg: 'amazon',
        text: ['亚马逊、速卖通、Wish、eBay、', 'Alibaba']
      },
      {
        title: '批量上传',
        svg: 'uploads',
        text: ['支持英文、简体中文、等多种语言']
      },
      {
        title: '产品评论',
        svg: 'comments',
        text: ['支持英文、简体中文、等多种语言']
      },
      {
        title: '自动水印',
        svg: 'mark-cn',
        text: ['添加产品时自动添加水印']
      }
    ]
  },
  {
    title: '促销',
    backgroundColor: '#F1881F',
    children: [
      {
        title: '电子券',
        svg: 'coupons',
        text: ['电子消费券可以抵金额以数字代码', '的形式呈现']
      },
      {
        title: '砍价活动',
        svg: 'sale',
        text: ['提高商品购买力，加大增大商品拉新', '促进用户活跃度']
      },
      {
        title: '拼团活动',
        svg: 'groupBuying',
        text: ['充分利用社交软件的作用利用社交这种', '方式吸引用户下单']
      },
      {
        title: '秒杀活动',
        svg: 'limitedTimeOffers',
        text: ['用抢购秒杀的方式处理一些积压的商品，', '降低库存成本']
      },
      {
        title: '分销活动',
        svg: 'topology',
        text: ['建立销售渠道，以利用', '返佣金的形式调动用户参与的积极性']
      }
    ]
  },
  {
    title: '营销',
    backgroundColor: '#7726BE',
    children: [
      {
        title: 'SEO插件',
        svg: 'seoExtension',
        text: ['网站地图、ALT标题标签、面包屑导航等']
      },
      {
        title: '分销插件',
        svg: 'distributionExtension',
        text: ['智能分销系统,引爆社交会员制推广']
      }
    ]
  },
  {
    title: '运营',
    backgroundColor: '#4CE2DD',
    children: [
      {
        title: '网页单页',
        svg: 'spa',
        text: ['内容页面的完善设置']
      },
      {
        title: '网站数据分析',
        svg: 'analyse',
        text: ['详情数据分析，提高网站运营效率']
      },
      {
        title: '自定义评论',
        svg: 'customComments',
        text: ['新站必备功能，一秒变多年老站']
      },
      {
        title: '买家秀',
        svg: 'buyerShow',
        text: ['买家晒图链接产品，增加客户体验']
      },
      {
        title: '访客快速购买',
        svg: 'quickPurchase',
        text: ['非会员快速下，增加转化率']
      },
      {
        title: '卖家秀',
        svg: 'sellerShow',
        text: ['卖家晒图链接产品，增加用户体验，产品曝光度']
      }
    ]
  },
  {
    title: '收款',
    backgroundColor: '#BBAB18',
    children: [
      {
        title: 'PayPal',
        svg: 'payPal',
        text: ['支持个人与企业账号收款，支持快捷支付和智能支付']
      },
      {
        title: '微信支付',
        svg: 'wechatPay',
        text: ['支持国内第三方支付通道']
      },
      {
        title: '国内信用卡支付',
        svg: 'creditCard',
        text: ['支持信用卡等多种支付方式']
      }
    ]
  },
  {
    title: '订单',
    backgroundColor: '#306DD8',
    children: [
      {
        title: '批量处理',
        svg: 'checkOrder',
        text: ['支持批量处理订单，一键导入快递']
      },
      {
        title: '软件通知',
        svg: 'notice',
        text: ['会员注册，下达发货等系统自动', '软件通知，提醒']
      },
      {
        title: '运单拆单',
        svg: 'splitOrder',
        text: ['订单可进拆分、满足卖家需求，', '增加购买率']
      }
    ]
  },
  {
    title: '物流',
    backgroundColor: '#EC5382',
    children: [
      {
        title: '运费设置',
        svg: 'freight',
        text: ['提供海运、空运和普通快递设置']
      },
      {
        title: '导入快递',
        svg: 'imports',
        text: ['一键导订单快递']
      },
      {
        title: '运费模板',
        svg: 'templates',
        text: ['不同产品可设置不同的物流方式']
      },
      {
        title: '库存管理',
        svg: 'stock',
        text: ['设置库存和虚拟库存、实时监控', '仓储信息']
      }
    ]
  },
  {
    title: '管理',
    backgroundColor: '#3DBC1F',
    children: [
      {
        title: '操作权限',
        svg: 'permissions',
        text: ['支持设定后台功能管理分配']
      },
      {
        title: '中英文后台',
        svg: 'language-multi',
        text: ['后台提供中英文界面操作，满足国内外', '运营人员需求']
      },
      {
        title: '供应商后台',
        svg: 'bill',
        text: ['入驻成为商家后，可以商家后台添加', '供应商账号，即可登录供应商后台', '批量发布产品']
      },
      {
        title: '门店后台',
        svg: 'home',
        text: ['平台自营和入驻商家都可以添加门', '店后台由门店管理人员管理，', '添加店员管理门店商品']
      }
    ]
  },
  {
    title: '服务',
    backgroundColor: '#6E4CE2',
    children: [
      {
        title: '专属顾问服务',
        svg: 'person-self',
        text: ['资深电商专业顾问服务']
      },
      {
        title: '一对一售后客服',
        svg: 'customerService',
        text: ['在线客服，7*24小时在线服务']
      },
      {
        title: '线下沙龙',
        svg: 'flag',
        text: ['不定期举办线沙龙培训']
      },
      {
        title: '合作伙伴推荐',
        svg: 'cooperation',
        text: ['为推广，直播，代理商，物流等', '需求提供专业合作伙伴']
      }
    ]
  }
]
