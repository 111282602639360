<template>
  <div class="module">
    <B2cNavMenu ref="b2cNav" :item-flag="0" :page="page" />
    <div class="container">
      <!--左侧菜单区导航区域 -->
      <div class="big-layout">
        <ul id="menu" class="menu">
          <li
            v-for="(menuItem, menukey) in moduleList"
            :key="menukey"
            :class="{ active: active === menukey }"
            class="leftNav-item"
            @click="scrollTo(menukey)"
          >
            {{ menuItem.title }}
          </li>
        </ul>
        <!-- 右侧内容区域 -->
        <div class="content">
          <div
            v-for="(item, index) in moduleList"
            :key="'mainItem' + index"
            class="box-item"
          >
            <div class="title li-title">
              <h2>{{ item.title }}</h2>
            </div>

            <div class="card-content">
              <div
                v-for="(e, i) in item.children"
                :key="index + '-' + i"
                class="card"
                :style="{ 'background-color': item.backgroundColor }"
              >
                <svg-icon class-name="main-svg" :icon-class="e.svg" />
                <p class="title">
                  {{ e.title }}
                </p>
                <p v-for="(el, index_) in e.text" :key="index_" class="text">
                  {{ el }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <gn-footer-banner />
  </div>
</template>

<script>
import B2cNavMenu from 'components/B2cNavMenu'
import { dataList } from './dataList'
import GnFooterBanner from '@/layout/footer/gnFooterBanner'

export default {
  name: 'Module',
  components: {
    GnFooterBanner,
    // BannerStation,
    B2cNavMenu
  },
  data() {
    return {
      moduleList: [],
      offsetHeight: 0,
      mainHeight: 0,
      isBottom: false,
      page: 'b2c',
      // 新内容
      bannerHeight: 0,
      b2dNavHeight: 0,
      active: 0 // 当前激活的导航索引
    }
  },
  created() {
    this.moduleList = dataList
    const routerText = this.$router.history.current.path.split('/')[1]
    if (routerText === 'business') {
      this.page = 'business'
    }
  },
  mounted() {
    // this.bannerHeight = this.$refs.banner.$el.offsetHeight
    this.b2dNavHeight = this.$refs.b2cNav.$el.offsetHeight

    // 新--
    // 监听滚动事件
    window.addEventListener('scroll', this.onScroll)
  },
  // 新--
  destroyed() {
    // 必须移除监听器，不然当该vue组件被销毁了，监听器还在就会出错
    window.removeEventListener('scroll', this.onScroll)
  },
  methods: {
    // 新--
    // 滚动监听器
    onScroll() {
      // 获取所有锚点元素
      const navContents = document.querySelectorAll('.content .box-item')
      // 所有锚点元素的 offsetTop
      const offsetTopArr = []
      navContents.forEach((item) => {
        offsetTopArr.push(item.offsetTop)
      })
      // 获取当前文档流的 scrollTop
      const scrollTop =
        document.documentElement.scrollTop + 40 || document.body.scrollTop + 40
      // 定义当前点亮的导航下标
      let navIndex = 0
      let count = 0 // (暂时不偏移,修改)
      const menu = document.getElementById('menu') // (暂时不偏移,修改)
      for (let n = 0; n < offsetTopArr.length; n++) {
        // 如果 scrollTop 大于等于第 n 个元素的 offsetTop,(修改:大于页面前面的操作) 则说明 n-1 的内容已经完全不可见
        // 那么此时导航索引就应该是 n 了
        if (scrollTop >= offsetTopArr[n] + this.b2dNavHeight + 80) {
          // + this.bannerHeight
          navIndex = n
          if (navIndex >= 9) count++ // (暂时不偏移,修改)
        }
      }
      // 当前active菜单赋值
      this.active = navIndex
      // 滚动到一定位置 将菜单整体向上移  确保能看的到更多的菜单(修改:左侧菜单偏移量-暂时不需要)
      if (navIndex >= 9) {
        menu.style.top = -100 * count + 'px' // 暂时不偏移(修改)
      } else {
        menu.style.top = 200 + 'px' // 暂时不偏移(修改)
      }

      // 新增如果偏移量未开始,导航消失
      if (navIndex === 0 && scrollTop <= offsetTopArr[0] + this.b2dNavHeight) {
        // + this.bannerHeight
        menu.style.display = 'none'
      } else if (
        navIndex === 9 &&
        scrollTop >= offsetTopArr[9] + this.b2dNavHeight + 150
      ) {
        // + this.bannerHeight 新增如果偏移量大于所有滑动的快,导航消失
        menu.style.display = 'none'
      } else {
        menu.style.display = 'unset'
      }

      // console.log(navIndex, '滑动的下标')
    },
    // 跳转到指定索引的元素
    scrollTo(index) {
      // 获取目标的 offsetTop
      // css选择器是从 1 开始计数，我们是从 0 开始，所以要 +1
      const targetOffsetTop =
        document.querySelector(`.content > .box-item:nth-child(${index + 1})`)
          .offsetTop +
        this.b2dNavHeight +
        80 //  + this.bannerHeight (修改,必须加上之前原本页面的偏移距离)
      // 获取当前 offsetTop
      let scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop
      // 定义一次跳 50 个像素，数字越大跳得越快，但是会有掉帧得感觉，步子迈大了会扯到蛋
      const STEP = 70
      // 判断是往下滑还是往上滑
      if (scrollTop > targetOffsetTop) {
        // 往上滑
        smoothUp()
      } else {
        // 往下滑
        smoothDown()
      }
      // 定义往下滑函数
      function smoothDown() {
        // 如果当前 scrollTop 小于 targetOffsetTop 说明视口还没滑到指定位置
        if (scrollTop < targetOffsetTop) {
          // 如果和目标相差距离大于等于 STEP 就跳 STEP
          // 否则直接跳到目标点，目标是为了防止跳过了。
          if (targetOffsetTop - scrollTop >= STEP) {
            scrollTop += STEP
          } else {
            scrollTop = targetOffsetTop
          }
          document.body.scrollTop = scrollTop
          document.documentElement.scrollTop = scrollTop
          // 关于 requestAnimationFrame 可以自己查一下，在这种场景下，相比 setInterval 性价比更高
          requestAnimationFrame(smoothDown)
        }
      }
      // 定义往上滑函数
      function smoothUp() {
        if (scrollTop > targetOffsetTop) {
          if (scrollTop - targetOffsetTop >= STEP) {
            scrollTop -= STEP
          } else {
            scrollTop = targetOffsetTop
          }
          document.body.scrollTop = scrollTop
          document.documentElement.scrollTop = scrollTop
          requestAnimationFrame(smoothUp)
        }
      }
    }
  }
}
</script>
<!-- > 1200 -->
<style lang="scss" scoped>
//banner
// 强行改变H2的标题字体
.li-title {
  font-size: 3rem !important;
}
.banner_bottom {
  width: 1903px;
  height: 375px;
  cursor: pointer;
}
//
.nav {
  margin-top: 68px;
}
//leftNav
.container {
  margin-top: 100px;
  position: relative;
  display: flex;
  justify-content: center;
}
.leftNav {
  color: #89acdd;
  width: 108px;
  font-size: 24px;
  position: absolute;
  top: 0;
  left: -20%;
  .leftNav-item {
    margin-top: 30px;
    cursor: pointer;
    &:nth-of-type(1) {
      margin-top: 10px;
    }
    &:after {
      content: "";
      display: inline-block;
      margin-left: 40px;
      width: 13px;
      height: 13px;
      border-radius: 50%;
      background-color: #89acdd;
    }
  }
}

</style>

<!-- 新样式 -->
<style lang="scss" scoped>
/* 内容区的样式 */
.content {
  position: relative;
  background-color: white;
  border-radius: 4px;
  margin: 10px 10px 0 150px;
}

/* div内容样式 */
.content > div {
  /* 主要内容的最大宽度 */
  max-width: 1192px;
  /* 最小高度 */
  /* min-height: 500px; */
  /* width: 100%; */
  /* text-align: center; */
  /* padding: 20px; */
  /* background: #ffffff; */
  /* box-shadow: 0 3px 10px 0 rgba(219, 219, 219, 0.5); */
  /* border-radius: 6px; */
  margin-top: 10px;
}
.content > div:not(:last-child) {
  margin-bottom: 2px;
}

/* 导航栏的样式 */
.menu {
  position: fixed;
  top: 200px;
  left: 20vh;
  /* background-color: #efefef; */
  width: 140px;
  z-index: 100;
  display: none;
}
.menu li {
  text-align: center;
  /* padding: 20px; */
  font-size: 24px;
  cursor: pointer;
  line-height: 50px;
  height: 50px;
  color: #89acdd;
  list-style: none;
}
.menu li:before {
  content: "";
  display: block;
  width: 10px;
  height: 10px;
  margin-top: 20px;
  position: absolute;
  right: 10px;
  border-radius: 50%;
  background-color: #89acdd;
}
.menu li:not(:last-child) {
  /* border-bottom: 1px solid #e0e0e0; */
}
.menu li:hover {
  -webkit-box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.2);
  box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.2);
}
/* 当导航被点亮后改变颜色 */
.menu .active {
  color: #ffffff;
  background-color: #dfe5ee;
}
</style>
<!-- 新>1200 -->
<style lang="scss" scoped>
// 十个那个
.box-item {
  display: flex;
  width: 1192px;
  flex-direction: column;
  > .title {
    font-size: 39px;
    position: relative;
    h2 {
      display: inline-block;
      position: relative;
      font-weight: 500;
      left: 300px;
      padding: 0 58px;
      background-color: #ffffff;
    }
    &:before {
      content: "";
      display: block;
      position: absolute;
      top: 50%;
      height: 2px;
      width: 100%;
      background-color: #3b2df0;
    }
  }
}

// 卡片的内容
.card-content {
  display: grid;
  margin-top: 40px;
  margin-bottom: 40px;
  grid-template-columns: repeat(4, 25%);
  min-height: 500px;
  grid-row-gap: 47px; //行间距
  justify-content: center;
  justify-items: center;
  //align-items: center;
  .card {
    width: 220px;
    height: 220px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #000;
    border-radius: 10px;
    align-items: center;
    color: white;
    .main-svg {
      width: 65px;
      height: 65px;
      margin-top: 17px;
    }
    .title {
      font-size: 6px;
      margin-top: 24px;
      margin-bottom: 24px;
    }
    .text {
      font-size: 12px;
    }
  }
}
</style>

<!-- 新的适配 -->
<style lang="scss" scoped>
//  width:320px-479px
@media only screen and (min-width: 320px) and (max-width: 479px) {
  .content {
    margin: unset;
  }
  // 隐藏menu
  .menu {
    display: none !important;
    z-index: -100 !important;
  }
  .box-item {
    display: flex;
    width: 280px;
    flex-direction: column;
    > .title {
      font-size: 16px;
      position: relative;
      h2 {
        display: inline-block;
        position: relative;
        font-weight: 250;
        left: 70px;
        padding: 0 18px;
        background-color: #ffffff;
      }
      &:before {
        content: "";
        display: block;
        position: absolute;
        top: 50%;
        height: 2px;
        width: 100%;
        background-color: #3b2df0;
      }
    }
  }

  // 卡片的内容
  .card-content {
    display: grid;
    margin-top: 20px;
    margin-bottom: 20px;
    grid-template-columns: repeat(2, 50%);
    min-height: 220px !important;
    grid-row-gap: 22px; //行间距
    justify-content: center;
    justify-items: center;
    //align-items: center;
    .card {
      width: 130px;
      height: 150px;
      display: flex;
      flex-direction: column;
      background-color: #000;
      border-radius: 10px;
      align-items: center;
      color: white;
      .main-svg {
        width: 32px;
        height: 32px;
        margin-top: 15px;
      }
      .title {
        font-size: 5px !important;
        margin-top: 12px;
        margin-bottom: 12px;
      }
      .text {
        font-size: 12px;
      }
    }
  }
}
</style>

<style lang="scss" scoped>
//  width:480px-575px
@media only screen and (min-width: 480px) and (max-width: 575px) {
  .content {
    margin: unset;
  }
  // 隐藏menu
  .menu {
    display: none !important;
    z-index: -100 !important;
  }
  .box-item {
    display: flex;
    width: 460px;
    flex-direction: column;
    > .title {
      font-size: 16px;
      position: relative;
      h2 {
        display: inline-block;
        position: relative;
        font-weight: 250;
        left: 70px;
        padding: 0 18px;
        background-color: #ffffff;
      }
      &:before {
        content: "";
        display: block;
        position: absolute;
        top: 50%;
        height: 2px;
        width: 100%;
        background-color: #3b2df0;
      }
    }
  }

  // 卡片的内容
  .card-content {
    display: grid;
    margin-top: 20px;
    margin-bottom: 20px;
    grid-template-columns: repeat(2, 50%);
    min-height: 220px !important;
    grid-row-gap: 22px; //行间距
    justify-content: center;
    justify-items: center;
    //align-items: center;
    .card {
      width: 180px;
      height: 180px;
      display: flex;
      flex-direction: column;
      background-color: #000;
      border-radius: 10px;
      align-items: center;
      color: white;
      .main-svg {
        width: 32px;
        height: 32px;
        margin-top: 15px;
      }
      .title {
        font-size: 5px !important;
        margin-top: 12px;
        margin-bottom: 12px;
      }
      .text {
        font-size: 12px;
      }
    }
  }
}
</style>

<style lang="scss" scoped>
//  width:576px-767px
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .content {
    margin: unset;
  }
  // 隐藏menu
  .menu {
    display: none !important;
    z-index: -100 !important;
  }
  .box-item {
    display: flex;
    width: 550px;
    flex-direction: column;
    > .title {
      font-size: 16px;
      position: relative;
      h2 {
        display: inline-block;
        position: relative;
        font-weight: 250;
        left: 70px;
        padding: 0 18px;
        background-color: #ffffff;
      }
      &:before {
        content: "";
        display: block;
        position: absolute;
        top: 50%;
        height: 2px;
        width: 100%;
        background-color: #3b2df0;
      }
    }
  }

  // 卡片的内容
  .card-content {
    display: grid;
    margin-top: 20px;
    margin-bottom: 20px;
    grid-template-columns: repeat(2, 50%);
    min-height: 220px !important;
    grid-row-gap: 22px; //行间距
    justify-content: center;
    justify-items: center;
    //align-items: center;
    .card {
      width: 220px;
      height: 220px;
      display: flex;
      flex-direction: column;
      background-color: #000;
      border-radius: 10px;
      align-items: center;
      color: white;
      .main-svg {
        width: 40px;
        height: 40px;
        margin-top: 15px;
      }
      .title {
        font-size: 10px !important;
        margin-top: 12px;
        margin-bottom: 12px;
      }
      .text {
        font-size: 12px;
      }
    }
  }
}
</style>

<style lang="scss" scoped>
//  width:768px-991px
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .content {
    margin: unset;
  }
  // 隐藏menu
  .menu {
    display: none !important;
    z-index: -100 !important;
  }
  .box-item {
    display: flex;
    width: 700px;
    flex-direction: column;
    > .title {
      font-size: 16px;
      position: relative;
      h2 {
        display: inline-block;
        position: relative;
        font-weight: 250;
        left: 70px;
        padding: 0 18px;
        background-color: #ffffff;
      }
      &:before {
        content: "";
        display: block;
        position: absolute;
        top: 50%;
        height: 2px;
        width: 100%;
        background-color: #3b2df0;
      }
    }
  }

  // 卡片的内容
  .card-content {
    display: grid;
    margin-top: 20px;
    margin-bottom: 20px;
    grid-template-columns: repeat(2, 50%);
    min-height: 220px !important;
    grid-row-gap: 22px; //行间距
    justify-content: center;
    justify-items: center;
    //align-items: center;
    .card {
      width: 300px;
      height: 250px;
      display: flex;
      flex-direction: column;
      background-color: #000;
      border-radius: 10px;
      align-items: center;
      color: white;
      .main-svg {
        width: 62px;
        height: 62px;
        margin-top: 15px;
      }
      .title {
        font-size: 10px !important;
        margin-top: 12px;
        margin-bottom: 12px;
      }
      .text {
        font-size: 17px;
      }
    }
  }
}
</style>

<style lang="scss" scoped>
//  width:992px-1199px
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .content {
    margin: unset;
  }
  // 隐藏menu
  .menu {
    display: none !important;
    z-index: -100 !important;
  }
  .box-item {
    display: flex;
    width: 950px;
    flex-direction: column;
    > .title {
      font-size: 16px;
      position: relative;
      h2 {
        display: inline-block;
        position: relative;
        font-weight: 250;
        left: 70px;
        padding: 0 18px;
        background-color: #ffffff;
      }
      &:before {
        content: "";
        display: block;
        position: absolute;
        top: 50%;
        height: 2px;
        width: 100%;
        background-color: #3b2df0;
      }
    }
  }

  // 卡片的内容
  .card-content {
    display: grid;
    margin-top: 20px;
    margin-bottom: 20px;
    grid-template-columns: repeat(2, 50%);
    min-height: 250px !important;
    grid-row-gap: 22px; //行间距
    justify-content: center;
    justify-items: center;
    //align-items: center;
    .card {
      width: 400px;
      height: 250px;
      display: flex;
      flex-direction: column;
      background-color: #000;
      border-radius: 10px;
      align-items: center;
      color: white;
      .main-svg {
        width: 62px;
        height: 62px;
        margin-top: 15px;
      }
      .title {
        font-size: 10px !important;
        margin-top: 12px;
        margin-bottom: 12px;
      }
      .text {
        font-size: 17px;
      }
    }
  }
}
</style>
<style lang="scss" scoped>
//  额外处理
@media only screen and (min-width: 1200px) and (max-width: 1700px) {
  .content {
    margin: unset;
  }
  // 隐藏menu
  .menu {
    display: none;  // 1200开始显示导航
    z-index: -100 !important;
    left: 4vw;
  }
  .box-item {
    display: flex;
    width: 65vw;
    flex-direction: column;
    > .title {
      font-size: 16px;
      position: relative;
      h2 {
        display: inline-block;
        position: relative;
        font-weight: 250;
        left: 70px;
        padding: 0 18px;
        background-color: #ffffff;
      }
      &:before {
        content: "";
        display: block;
        position: absolute;
        top: 50%;
        height: 2px;
        width: 100%;
        background-color: #3b2df0;
      }
    }
  }

  // 卡片的内容
  .card-content {
    display: grid;
    margin-top: 20px;
    margin-bottom: 20px;
    grid-template-columns: repeat(2, 50%);
    min-height: 250px !important;
    grid-row-gap: 22px; //行间距
    justify-content: center;
    justify-items: center;
    //align-items: center;
    .card {
      width: 27vw;
      height: 250px;
      display: flex;
      flex-direction: column;
      background-color: #000;
      border-radius: 10px;
      align-items: center;
      color: white;
      .main-svg {
        width: 62px;
        height: 62px;
        margin-top: 15px;
      }
      .title {
        font-size: 10px !important;
        margin-top: 12px;
        margin-bottom: 12px;
      }
      .text {
        font-size: 17px;
      }
    }
  }
}
</style>
